



































































































































































































































































































































































































































































































































































































































































































































































import "bootstrap-vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { GipDataClass, ValidationErrors } from "./gip-types";
import { IAbp } from "@/modules/budget/bip/bip-types";
import Multiselect from "vue-multiselect";
import { IKato } from "@/modules/user-management/user-management-int";
import DatePicker from "vue2-datepicker";
import store from "@/services/store";

@Component({
    name: "gipCard",
    components: { multiselect: Multiselect, DatePicker },
})
export default class GipCard extends Vue {
    @Prop({
        required: true,
        default: () => new GipDataClass(),
    })
    private gip!: GipDataClass;
    @Prop({
        type: String,
        default: "view",
        validator: (value: string) =>
            ["view", "edit", "create"].includes(value),
    })
    private mode!: string;
    @Prop({ required: true }) save!: boolean;
    @Prop({ required: true }) activeTab!: number;
    @Prop({ required: true }) validationErrors!: ValidationErrors;
    private localValidationErrors: any = { ...this.validationErrors };
    @Prop({ required: true }) private abpOptions!: IAbp[];
    private localAbpOptions: IAbp[] = [...this.abpOptions];
    @Prop({ required: true, default: null }) private selectedAbp!: any | null;
    @Prop({ required: true }) private prgOptions!: any[];
    private localPrgOptions: any[] = [...this.prgOptions];
    @Prop({ required: true, default: null }) private selectedPrg!: any | null;
    @Prop({ required: true, default: () => [] }) private regionOptions!: any[];
    private localRegionOptions: any[] = [...this.regionOptions];
    @Prop({ required: true, default: null }) private selectedRegion!:
        | any
        | null;
    @Prop({ required: true }) private katoOptions!: IKato[];
    private localKatoOptions: IKato[] = [...this.katoOptions];
    @Prop({ required: true, default: null })
    private selectedKato!: IKato | null;

    @Prop({ required: true }) private projectPrgOptions!: any[];
    private localProjectPrgOptions: any[] = [...this.projectPrgOptions];
    @Prop({ required: true, default: null }) private selectedProjectPrg!:
        | any
        | null;

    @Prop({ required: true }) private unitOptions!: any[];
    private localUnitOptions: any[] = [...this.unitOptions];
    @Prop({ required: true, default: null }) private selectedUnit!: any | null;

    @Prop({ required: true }) private fundingSrcOptions!: any[];
    private localFundingSrcOptions: any[] = [...this.fundingSrcOptions];
    @Prop({ required: true, default: null }) private selectedFunding!:
        | any
        | null;

    @Prop({ required: true }) private branchOptions!: any[];
    private localBranchOptions: any[] = [...this.branchOptions];
    @Prop({ required: true, default: null }) private selectedBranch!:
        | any
        | null;

    private minDate: Date = new Date(new Date().getFullYear() - 10, 0); // Январь 10 лет назад
    private maxDate: Date = new Date(new Date().getFullYear() + 10, 11); // Декабрь через 10 лет

    private dateFromForInput: Date | null = new Date(
        new Date().getFullYear(),
        new Date().getMonth()
    );

    private dateToForInput: Date | null = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1
    );

    get userId() {
        return store.getters.user_uuid;
    }

    get currentRegion() {
        if (this.selectedRegion) {
            return this.selectedRegion;
        }
        const matchedOption = this.localRegionOptions.find(
            (option: any) => option.text === this.gip?.budgetRegion
        );
        return matchedOption || null;
    }
    set currentRegion(value: any | null) {
        this.$emit("update:selectedRegion", value);
    }

    get currentAbp() {
        if (this.selectedAbp) {
            return this.selectedAbp;
        }
        const matchedOption = this.localAbpOptions.find(
            (option: any) => option.text === this.gip?.abp
        );
        return matchedOption || null;
    }

    set currentAbp(value: any | null) {
        this.$emit("update:selectedAbp", value);
    }

    get currentPrg() {
        return (
            this.selectedPrg ||
            this.localPrgOptions.find(
                (option) => option.text === this.gip?.prg
            ) ||
            null
        );
    }

    set currentPrg(value: any | null) {
        this.$emit("update:selectedPrg", value);
    }

    get currentKato() {
        if (this.selectedKato) {
            return this.selectedKato;
        }
        const matchedOption = this.localKatoOptions.find(
            (option: any) => option.text === this.gip?.location
        );
        return matchedOption || null;
    }
    set currentKato(value: IKato | null) {
        this.$emit("update:selectedKato", value);
    }

    get currentBranch() {
        if (this.selectedBranch) {
            return this.selectedBranch;
        }
        const matchedOption = this.localBranchOptions.find(
            (option: any) => option.name_ru === this.gip?.projectBranch
        );
        return matchedOption || null;
    }
    set currentBranch(value: any | null) {
        this.$emit("update:selectedBranch", value);
    }

    get currentFundingSrc() {
        if (this.selectedFunding) {
            return this.selectedFunding;
        }
        const matchedOption = this.localFundingSrcOptions.find(
            (option: any) => option.name_ru === this.gip?.srcFunding
        );
        return matchedOption || null;
    }
    set currentFundingSrc(value: any | null) {
        this.$emit("update:selectedFunding", value);
    }
    get currentProjectPrg() {
        if (this.selectedProjectPrg) {
            return this.selectedProjectPrg;
        }
        const matchedOption = this.localProjectPrgOptions.find(
            (option: any) => option.name_ru === this.gip?.projectConcept
        );
        return matchedOption || null;
    }
    set currentProjectPrg(value: any | null) {
        this.$emit("update:selectedProjectPrg", value);
    }
    get currentUnit() {
        if (this.selectedUnit) {
            return this.selectedUnit;
        }
        const matchedOption = this.localUnitOptions.find(
            (option: any) => option.text === this.gip?.unit
        );
        return matchedOption || null;
    }
    set currentUnit(value: any | null) {
        this.$emit("update:selectedUnit", value);
    }

    get isViewable() {
        return this.mode === "view";
    }

    get isEditable() {
        return this.mode === "edit";
    }

    get isCreatable() {
        return this.mode === "create";
    }

    get yearsInRange() {
        const years = [];
        const startYear = this.gip.dateFromForInput?.getFullYear();
        const endYear = this.gip.dateToForInput?.getFullYear();

        if (startYear && endYear && startYear <= endYear) {
            for (let year = startYear; year <= endYear; year++) {
                years.push(year);
            }
        }
        return years;
    }

    get formattedPlanData(): Array<Record<string, number | null | string>> {
        if (
            !this.gip.dateFromForInput ||
            !this.gip.dateToForInput ||
            !this.gip.plan ||
            this.gip.plan.length === 0
        ) {
            const begYear = this.gip.dateFromForInput?.getFullYear() ?? 0;
            const endYear = this.gip.dateToForInput?.getFullYear() ?? 0;

            if (!begYear || !endYear || begYear > endYear) {
                return [];
            }

            const emptyData: Record<string, number | null> = {};
            for (let year = begYear; year <= endYear; year++) {
                emptyData[`year_${year}`] = null;
            }

            return [emptyData];
        }

        const data: Record<string, number | null> = {};
        this.gip.plan.forEach((p) => {
            const yearKey = `year_${p.year}`;
            const value = this.formatPlanValue(p.value);
            data[yearKey] = isNaN(value) ? null : value;
        });

        return [data];
    }

    private formatPlanValue(value: any): number {
        const num = Number(value);
        return isNaN(num) ? 0 : num;
    }

    private formatNumber(value: number): string {
        return new Intl.NumberFormat("ru-RU", {
            style: "decimal",
            maximumFractionDigits: 1,
            minimumFractionDigits: 1,
        }).format(value);
    }
    get tableFields() {
        const years = this.yearsInRange;
        return years.map((year) => ({
            key: `year_${year}`,
            label: `${year}`,
        }));
    }

    private validatePlanValues(status: boolean): ValidationErrors {
        const errors: ValidationErrors = {};

        this.formattedPlanData.forEach((row) => {
            this.tableFields.forEach((field) => {
                const value = row[field.key];

                if (status) {
                    if (value === undefined || (value && value < "0")) {
                        const errorMessage = `Пожалуйста, заполните плановое значение за: ${
                            field.label || field.key
                        } год. Данные обязательно должны быть положительным числом!`;
                        errors[`${field.key}`] = errorMessage;

                        this.makeToast(
                            "danger",
                            errorMessage,
                            "Ошибка сохранения"
                        );
                    }
                } else {
                    if (
                        value !== undefined &&
                        typeof value === "number" &&
                        value < 0
                    ) {
                        const errorMessage = `Плановые значения за ${
                            field.label || field.key
                        } год должны быть положительным числом!`;
                        errors[`${field.key}`] = errorMessage;

                        this.makeToast(
                            "danger",
                            errorMessage,
                            "Ошибка сохранения"
                        );
                    }
                }
            });
        });

        this.localValidationErrors = errors;
        this.$emit("validation-complete", errors);
        return errors;
    }

    public async created() {
        this.$watch("regionOptions", (newOptions: any) => {
            this.localRegionOptions = [...newOptions];
        });
        this.$watch("abpOptions", (newOptions: any) => {
            this.localAbpOptions = [...newOptions];
        });

        this.$watch("prgOptions", (newOptions: any) => {
            this.localPrgOptions = [...newOptions];
        });
        this.$watch("katoOptions", (newOptions: any) => {
            this.localKatoOptions = [...newOptions];
        });
        this.$watch("branchOptions", (newOptions: any) => {
            this.localBranchOptions = [...newOptions];
        });
        this.$watch("fundingSrcOptions", (newOptions: any) => {
            this.localFundingSrcOptions = [...newOptions];
        });
        this.$watch("projectPrgOptions", (newOptions: any) => {
            this.localProjectPrgOptions = [...newOptions];
        });
        this.$watch("unitOptions", (newOptions: any) => {
            this.localUnitOptions = [...newOptions];
        });
        this.$watch("validationErrors", (newErrors: any) => {
            this.localValidationErrors = { ...newErrors };
        });
    }

    public mounted() {}

    private disableDate(date: Date | null): boolean {
        if (!date) {
            return true;
        }

        const year = date.getFullYear();
        const month = date.getMonth();

        return (
            year < this.minDate.getFullYear() ||
            year > this.maxDate.getFullYear() ||
            (year === this.minDate.getFullYear() &&
                month < this.minDate.getMonth()) ||
            (year === this.maxDate.getFullYear() &&
                month > this.maxDate.getMonth())
        );
    }

    private formatStartDate(date: Date): string {
        const year = date.getFullYear();
        const month = date.getMonth();

        const formattedDate = new Date(Date.UTC(year, month, 1));
        return formattedDate.toISOString();
    }

    private formatEndDate(date: Date): string {
        const year = date.getFullYear();
        const month = date.getMonth();
        const lastDay = new Date(Date.UTC(year, month + 1, 0)).getDate();

        const formattedDate = new Date(Date.UTC(year, month, lastDay));
        return formattedDate.toISOString();
    }

    private formatDateToMMYYYY(date: Date | null | undefined): string {
        if (!date) return "";
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${month}/${year}`;
    }

    private validateDateRange(source: string): void {
        if (this.gip.dateFromForInput && this.gip.dateToForInput) {
            if (source === "from") {
                if (this.gip.dateFromForInput > this.gip.dateToForInput) {
                    this.gip.dateToForInput = new Date(
                        this.gip.dateFromForInput
                    );
                }
            } else if (source === "to") {
                if (this.gip.dateToForInput < this.gip.dateFromForInput) {
                    this.gip.dateFromForInput = new Date(
                        this.gip.dateToForInput
                    );
                }
            }

            const minDate = new Date(2000, 0); // Январь 2000
            const maxDate = new Date(9999, 11); // Декабрь 9999

            if (this.gip.dateFromForInput < minDate) {
                this.gip.dateFromForInput = minDate;
            } else if (this.gip.dateFromForInput > maxDate) {
                this.gip.dateFromForInput = maxDate;
            }

            if (this.gip.dateToForInput < minDate) {
                this.gip.dateToForInput = minDate;
            } else if (this.gip.dateToForInput > maxDate) {
                this.gip.dateToForInput = maxDate;
            }
        }
    }

    private extractCode(value: string) {
        if (!value || typeof value !== "string") {
            return null;
        }
        const [code] = value.split(" - ");
        return code ? parseInt(code.trim()) : null;
    }
    private getBranchCodeByName(name: string): string | null {
        const matchedOption = this.localBranchOptions.find(
            (option: any) => option.name_ru === name
        );
        return matchedOption?.code || null;
    }

    private getFundingCodeByName(name: string): string | null {
        const matchedOption = this.localFundingSrcOptions.find(
            (option: any) => option.name_ru === name
        );
        return matchedOption?.code || null;
    }

    private getPrgPrjCodeByName(name: string): string | null {
        const matchedOption = this.localProjectPrgOptions.find(
            (option: any) => option.name_ru === name
        );
        return matchedOption?.code || null;
    }

    private getUnitCodeByName(name: string): string | null {
        const matchedOption = this.localUnitOptions.find(
            (option: any) => option.text === name
        );
        return matchedOption?.code || null;
    }

    getFormData() {
        const years = this.yearsInRange;
        const planValues = years.map((year) => ({
            year: year,
            value: Number(this.formattedPlanData[0][`year_${year}`]) || 0,
        }));

        return {
            ...(this.gip.id && { id: this.gip.id }),
            budget_region:
                this.selectedRegion?.code ||
                this.extractCode(this.gip.budgetRegion) ||
                null,
            name_kz: this.gip.nameKk,
            name_ru: this.gip.nameRu,
            abp:
                this.selectedAbp?.abp || this.extractCode(this.gip.abp) || null,
            is_archive: this.gip.projectStatus === "archived",
            funding:
                this.selectedFunding?.code ||
                this.getFundingCodeByName(this.gip.srcFunding) ||
                null,
            amount_rep_budget: this.gip.republicanBudgetAmount,
            amount_local_budget: this.gip.localBudgetAmount,
            project_type: "development",
            branch:
                this.selectedBranch?.code ||
                this.getBranchCodeByName(this.gip.projectBranch) ||
                null,
            prg:
                this.selectedPrg?.prg || this.extractCode(this.gip.prg) || null,
            concept:
                this.selectedProjectPrg?.code ||
                this.getPrgPrjCodeByName(this.gip.projectConcept) ||
                null,
            begin_date: this.gip.dateFromForInput
                ? this.formatStartDate(this.gip.dateFromForInput)
                : null,
            end_date: this.gip.dateToForInput
                ? this.formatEndDate(this.gip.dateToForInput)
                : null,
            location:
                this.selectedKato?.code ||
                this.extractCode(this.gip.location) ||
                null,
            purpose_kz: this.gip.targetKk,
            purpose_ru: this.gip.targetRu,
            task_name_kz: this.gip.taskKk,
            task_name_ru: this.gip.taskRu,
            result_kz: this.gip.resultKk,
            result_ru: this.gip.resultRu,
            indicator_kz: this.gip.targetIndicatorKk,
            indicator_ru: this.gip.targetIndicatorRu,
            direct_result_kz: this.gip.directResKk,
            direct_result_ru: this.gip.directResRu,
            final_result_kz: this.gip.finalResKk,
            final_result_ru: this.gip.finalResRu,
            key_indicator_kz: this.gip.keyIndicatorKk,
            key_indicator_ru: this.gip.keyIndicatorRu,
            unit:
                this.selectedUnit?.code ||
                this.getUnitCodeByName(this.gip.unit) ||
                null,
            user_id: this.userId,
            plan_values: planValues,
        };
    }

    private resetGipData() {
        this.gip = new GipDataClass();
    }

    private resetValidationErrors() {
        Object.keys(this.validationErrors).forEach((key) => {
            this.$set(this.validationErrors, key, undefined);
        });
    }
    makeToast(variant: string | undefined, tostbody: any, title: string) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: "b-toaster-top-center",
            autoHideDelay: 2000,
            solid: true,
            appendToast: true,
        });
    }
}
