
























import { Component, Vue } from "vue-property-decorator";
import InvestProjects from "./InvestProjects.vue";
import CurrentProjects from "./CurrentProjects.vue";

@Component({
    components: {
        investProjects: InvestProjects,
        currentProjects: CurrentProjects,
    },
})
export default class ProjectsBank extends Vue {
    private tab: number = 0;
    get hasAccessModule(): boolean {
        const moduleCode = "003.008";
        const accessLevel = this.$store.state.user.userModules.find(
            (it: any) => it.modules === moduleCode
        );
        return !!accessLevel;
    }
}
