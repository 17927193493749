export interface IProject {
    code: string;
    nameRu: string;
    nameKk: string;
    abp: string;
    prg: string;
    dateFromForInput: Date;
    dateToForInput: Date;
    budgetRegion: string;
    location: string;
    republicanBudgetAmount: number; // Сумма из РБ
    localBudgetAmount: number; // Сумма из МБ
    srcData?: string;
    srcFunding?: string;
    projectStatus: "active" | "archived";
    projectBranch?: string; // Отрасль
    projectConcept?: string; //Программа, региональный проект, национальный проект, концепция
    plan?: Array<{ year: number; value: number }>; // Плановые значения: год и значение
    targetRu?: string;
    taskRu?: string;
    resultRu?: string;
    targetIndicatorRu?: string;
    directResRu?: string;
    finalResRu?: string;
    keyIndicatorRu?: string;
    targetKk?: string;
    taskKk?: string;
    resultKk?: string;
    targetIndicatorKk?: string;
    directResKk?: string;
    finalResKk?: string;
    keyIndicatorKk?: string;
    unit?: string;
}

export abstract class BaseProject implements IProject {
    public id = 0;
    public code = "";
    public nameRu = "";
    public nameKk = "";
    public abp = "";
    public prg = "";
    public dateFromForInput = new Date();
    public dateToForInput = new Date();
    public budgetRegion = "";
    public location = "";
    public republicanBudgetAmount = 0;
    public localBudgetAmount = 0;
    public srcData = "";
    public projectStatus: "active" | "archived" = "active";
    public srcFunding = "";
    public projectBranch = "";
    public projectConcept = "";
    public plan: Array<{ year: number; value: number }> = [];
    public targetRu = "";
    public taskRu = "";
    public resultRu = "";
    public targetIndicatorRu = "";
    public directResRu = "";
    public finalResRu = "";
    public keyIndicatorRu = "";
    public targetKk = "";
    public taskKk = "";
    public resultKk = "";
    public targetIndicatorKk = "";
    public directResKk = "";
    public finalResKk = "";
    public keyIndicatorKk = "";
    public unit = "";

    constructor(codePrefix: string) {
        this.code = `${codePrefix}-00000`;
    }
}

export class GipDataClass extends BaseProject {
    constructor() {
        super("GIP");
    }
}
export class CurProjectDataClass extends BaseProject {
    public srcData = "current";
    constructor() {
        super("CE");
    }
}

export interface ValidationErrors {
    [key: string]: string | undefined;
}
