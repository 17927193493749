

































































































































































































































































































































































































































































































































































































import "bootstrap-vue";
import Vue from "vue";
import Component from "vue-class-component";
import Multiselect from "vue-multiselect";
import VueElementLoading from "vue-element-loading";
import store from "@/services/store";
import GipCard from "./gip-card.vue";
import { IProject, GipDataClass, ValidationErrors } from "./gip-types";
import axios from "axios";
import { Prop, Watch } from "vue-property-decorator";

@Component({
    name: "investProjects",
    components: {
        multiselect: Multiselect,
        loading: VueElementLoading,
        GipCard,
    },
})
export default class InvestProjects extends Vue {
    $refs!: {
        drop: HTMLFormElement;
        addRef: HTMLFormElement;
        region: HTMLFormElement;
        abp: HTMLFormElement;
        prg: HTMLFormElement;
        snpReg: HTMLFormElement;
        period: HTMLFormElement;
        status: HTMLFormElement;
        gipCard: HTMLFormElement;
        gipList: HTMLFormElement;
        code: HTMLFormElement;
    };

    @Prop({ type: Number, required: true }) readonly activeTab!: number;
    private selectedCode: any = null;
    private isGipCodeSelected: boolean = false;
    private codeItem: string = "Код проекта";
    private codeList: any = [];
    private regionList: any[] = [];
    private selectedRegion: any = null;
    private regionItem: string = "Регион";
    private abpList: any[] = [];
    private selectedAbp: any = null;
    private abpItem: string = "AБП";
    private prgList: any[] = [];
    private selectedPrg: any = null;
    private regionsSnp: any[] = [];
    private selectedRegSnp: any = null;
    private bpItem: string = "БП";
    private realYearList: any[] = [];
    private selectedFromYear: number = new Date().getFullYear();
    private selectedToYear: number = new Date().getFullYear();
    private realPlaceItem: string = "Место реализации";
    private realYearItem: string = "Период реализации";
    private isInvestActive: boolean = true;
    private isInvestArchived: boolean = true;

    private tempSelectedCode: any = null;
    private tempSelectedRegion: any = null;
    private tempSelectedAbp: any = null;
    private tempSelectedPrg: any = null;
    private tempSelectedRegSnp: any = null;
    private tempSelectedFromYear: number = new Date().getFullYear();
    private tempSelectedToYear: number = new Date().getFullYear();
    private tempIsInvestActive: boolean = true;
    private tempIsInvestArchived: boolean = true;

    private fundingSrcList: any[] = [];
    private selectedFunding: any = null;
    private projectPrgList: any[] = [];
    private selectedProjectPrg: any = null;
    private selectedBranch: any = null;
    private branchList: any[] = [];
    private selectedUnit: any = null;
    private unitList: any[] = [];
    private mode: "view" | "edit" | "create" = "view";
    private isGipSaving: boolean = false;
    private isGipEditing: boolean = false;
    private isAddModalVisible: boolean = false;
    private showCloseModal: boolean = false;
    private isEdited: boolean = false;
    private originalGipData: any = {};
    private searchProject: string = "";
    private resStatus: boolean = false;
    private response: boolean = false;
    private showDetailForm: boolean = false;
    private bar: number = 0;
    private loading: boolean = false;
    private showDelModal: boolean = false;
    private oblKato: any = null;
    private backList = true;
    private gipData: GipDataClass = new GipDataClass();
    private selectedProject: GipDataClass | null = null;
    private investProjectList: IProject[] = [];
    private get projectTableFields() {
        return [
            {
                label: "№",
                key: "nom",
            },
            {
                label: "КОД ПРОЕКТА",
                key: "code",
                sortable: true,
            },
            {
                label: "НАИМЕНОВАНИЕ ПРОЕКТА",
                key: "name",
                sortable: true,
            },
            {
                label: "АБП",
                key: "abp",
                sortable: true,
            },
            {
                label: "БП",
                key: "prg",
                sortable: true,
            },
            {
                label: "ПЕРИОД РЕАЛИЗАЦИИ",
                key: "period",
                sortable: true,
            },
            {
                label: "МЕСТО РЕАЛИЗАЦИИ",
                key: "location",
                sortable: true,
            },
            {
                label: "СУММА ИЗ РБ (тыс.тенге)",
                key: "rep_budget_sum",
                sortable: true,
            },
            {
                label: "СУММА ИЗ МБ (тыс.тенге)",
                key: "local_budget_sum",
                sortable: true,
            },
            {
                label: "Статус проекта",
                key: "projectStatus",
                sortable: true,
            },
            {
                label: "Источник",
                key: "projectSrc",
                sortable: true,
            },
            {
                label: "",
                key: "extra",
            },
        ];
    }

    get userId() {
        return store.getters.user_uuid;
    }
    get oblCode() {
        return this.oblKato;
    }

    get hasEditAccess(): boolean {
        const moduleCode = "003.008.001";
        const accessLevel = this.$store.state.user.userModules.find(
            (it: any) => it.modules === moduleCode
        )?.access_level;
        return accessLevel >= 2;
    }

    public async created() {
        try {
            this.$watch("tempSelectedRegion", (newValue) => {
                this.tempSelectedAbp = null;
                this.loadAbpData(newValue, true);
            });
            this.$watch("selectedRegion", (newValue) => {
                if (this.tempSelectedRegion) {
                    this.selectedAbp = this.tempSelectedAbp;
                } else {
                    this.selectedAbp = null;
                }
                this.loadAbpData(newValue, false);
            });
            this.$watch("tempSelectedAbp", (newValue) => {
                this.tempSelectedPrg = null;
                this.loadPrgData(newValue);
            });
            this.$watch("selectedAbp", (newValue) => {
                if (this.tempSelectedPrg) {
                    this.selectedPrg = this.tempSelectedPrg;
                } else {
                    this.selectedPrg = null;
                    this.gipData.prg = null as unknown as string;
                }
                this.loadPrgData(newValue);
            });
            this.$watch("tempSelectedCode", (newValue) => {
                this.isGipCodeSelected = !!newValue;
                if (this.isGipCodeSelected) {
                    this.resetFiltersForCode();
                }
            });
            this.$watch("isGipSaving", (newValue: boolean) => {
                if (newValue) {
                    this.getInvestProjectList();
                    this.getCodeList();
                }
            });

            await this.fetchObl();
            await this.loadRegion();
            await this.loadRegionsSnp();
            await this.getCodeList();
            await this.getProjectPrg();
            await this.getUnits();
            await this.getFundingSrc();
            await this.getProjectBranches();
            await this.getInvestProjectList();
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка в процессе инициализации:",
                    error.message
                );
            }
        }
    }

    public mounted() {}

    private getProjectStatusLabel(status: string): string {
        if (status === "active") return "Действующий";
        if (status === "archived") return "Архивный";
        if (status === "all") return "Все";
        return "";
    }

    private validateYears(source: string) {
        const isValidYear = (year: any): boolean => {
            return (
                !isNaN(year) &&
                Number(year) >= 1900 &&
                Number(year) <= 9999 &&
                year.toString().length === 4
            );
        };

        if (source === "from") {
            if (!isValidYear(this.tempSelectedFromYear)) {
                this.tempSelectedFromYear = new Date().getFullYear();
            }
            if (this.tempSelectedFromYear > this.tempSelectedToYear) {
                this.tempSelectedToYear = this.tempSelectedFromYear;
            }
        } else if (source === "to") {
            if (!isValidYear(this.tempSelectedToYear)) {
                this.tempSelectedToYear = new Date().getFullYear();
            }
            if (this.tempSelectedToYear < this.tempSelectedFromYear) {
                this.tempSelectedFromYear = this.tempSelectedToYear;
            }
        }
    }

    private async getInvestProjectList() {
        try {
            this.loading = true;
            this.bar = 0;

            const queryParams: any = {
                ...(this.selectedCode && { code: this.selectedCode }),
                obl: this.oblCode,
                ...(this.selectedRegion && {
                    budget_region: this.selectedRegion.code,
                }),
                ...(this.selectedAbp && { abp: this.selectedAbp.abp }),
                ...(this.selectedPrg && { prg: this.selectedPrg.prg }),
                ...(this.selectedRegSnp && {
                    location: this.selectedRegSnp.code,
                }),
                ...(this.selectedFromYear && {
                    begin_date: `${this.selectedFromYear}-01-01`,
                }),
                ...(this.selectedToYear && {
                    end_date: `${this.selectedToYear}-12-31`,
                }),
                project_type: "development,imported",
                ...(this.isInvestActive === true && {
                    is_archive: false,
                }),
                ...(this.isInvestArchived === true && {
                    is_archive: true,
                }),
                ...(this.isInvestActive === true &&
                    this.isInvestArchived === true && {
                        is_archive: "false,true",
                    }),
            };

            const response = await axios.get(
                `api-py/get-project-bank-list/${this.userId}`,
                {
                    params: queryParams,
                }
            );

            const projects = response.data;

            if (this.selectedCode && !this.selectedRegion) {
                const firstProject = projects[0];
                if (firstProject?.budget_region) {
                    const budgetRegionCode = firstProject.budget_region;
                    const abpResponse = await axios.get(
                        `/api-py/get-abps-by-region/${budgetRegionCode}`
                    );
                    this.abpList = abpResponse.data;
                }
            }

            const uniqueAbpCodes = [
                ...new Set(projects.map((item: any) => item.abp)),
            ];

            const abpDataPromises = uniqueAbpCodes.map((abpCode) =>
                axios
                    .get(`/api-py/actual-prg-by-abp/${abpCode}`)
                    .then((res) => ({
                        abp: abpCode,
                        programs: res.data,
                    }))
            );

            const abpDataResults = await Promise.all(abpDataPromises);

            const investProjectList = projects.map((item: any) => {
                const abpData = abpDataResults.find(
                    (data) => data.abp === item.abp
                );
                const program = abpData?.programs.find(
                    (prg: any) => prg.prg === item.prg
                );

                return {
                    ...item,
                    begin_date: item.begin_date
                        ? new Date(item.begin_date)
                        : null,
                    end_date: item.end_date ? new Date(item.end_date) : null,
                    projectStatus:
                        item.is_archive && item.is_archive === true
                            ? "Архивный"
                            : "Действующий",
                    srcProject:
                        item.project_type === "development"
                            ? "eAkimat"
                            : "ИСГП",
                    abpName:
                        this.abpList.find((abp: any) => abp.abp === item.abp)
                            ?.text || item.abp,
                    prgName: program
                        ? `${program.prg} - ${program.name_ru}`
                        : item.prg,
                    locationName:
                        this.regionsSnp.find(
                            (region: any) => region.code === item.location
                        )?.name || item.location,
                };
            });

            this.investProjectList = investProjectList;
            // console.log(this.investProjectList);
            this.bar = 100;
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка запроса списка проектов",
                    error.message
                );
            }
        } finally {
            this.loading = false;
        }
    }

    private async getCodeList() {
        try {
            const projectType = "imported,development";
            const response = await axios.get(
                `api-py/get-project-bank-code-list/${projectType}/${this.oblCode}/${this.userId}`
            );
            this.codeList = response.data.map(
                (item: { code: string }) => item.code
            );
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast("danger", "Ошибка запроса кодов", error.message);
            }
        }
    }

    private async loadRegion() {
        try {
            const response = await fetch(
                "/api-py/get-user-regions-by-obl/" +
                    this.oblCode +
                    "/" +
                    this.userId
            ).then((response) => response.json());
            this.regionList = response;
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка запроса регионов",
                    error.message
                );
            }
        } finally {
            this.selectedRegion = this.regionList[0];
            this.tempSelectedRegion = this.regionList[0];
        }
    }

    async fetchObl() {
        try {
            await fetch(
                "/api-py/get-budget-obl/" + this.$store.state._instanceCode
            )
                .then((response) => response.json())
                .then((json) => {
                    this.oblKato = json.obl;
                });
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка запроса кода области",
                    error.message
                );
            }
        }
    }

    private async fetchAbp(regionCode: string): Promise<any[]> {
        try {
            const regionResponse = await fetch(
                `/api-py/get-abps-by-region/${regionCode}`
            );
            const abpsOfRegion = await regionResponse.json();

            const userResponse = await fetch(`/api-py/user-abp/${this.userId}`);
            const abpsOfUser = await userResponse.json();

            const userAbpsSet = new Set(
                abpsOfUser.map((item: any) => item.abp)
            );
            const availableAbps = abpsOfRegion.filter((item: any) =>
                userAbpsSet.has(item.abp)
            );

            const seenAbps = new Set();
            return availableAbps.filter((item: any) => {
                if (seenAbps.has(item.abp)) {
                    return false;
                }
                seenAbps.add(item.abp);
                return true;
            });
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка запроса АБП",
                    error.toString()
                );
            }
            return [];
        }
    }

    private async fetchPrg(abp: any): Promise<any[]> {
        try {
            const response = await fetch(
                `/api-py/actual-prg-by-abp/${abp.abp}`
            );
            const prgData = await response.json();
            return prgData.map((item: any) => ({
                ...item,
                text: `${item.prg} - ${item.name_ru}`,
            }));
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка запроса Программы",
                    error.toString()
                );
            }
            return [];
        }
    }

    private async loadAbpData(region: any, isFilter: boolean) {
        if (region?.code) {
            const abpList = await this.fetchAbp(region.code);
            this.abpList = abpList.sort((a, b) => a.abp - b.abp);
            if (isFilter) {
                await this.loadPrgData(this.tempSelectedAbp);
            } else {
                await this.loadPrgData(this.selectedAbp);
            }
        }
    }

    private async loadPrgData(abp: any) {
        if (abp) {
            const prgList = await this.fetchPrg(abp);
            this.prgList = prgList;
        }
    }

    private async loadRegionsSnp() {
        try {
            const snpResponse = await fetch("/api-py/user-kato/" + this.userId);
            const snpData = await snpResponse.json();

            if (snpData && snpData.length > 0) {
                const katoResponse = await fetch(
                    "/api-py/get-katos-by-obl/" + this.oblCode
                );
                const katoData = await katoResponse.json();
                this.regionsSnp = snpData.map((item: any) => {
                    const foundRegion = katoData.find(
                        (region: any) => region.code === item.kato
                    );
                    return {
                        code: item.kato,
                        name: foundRegion ? foundRegion.name_ru : "",
                        text: `${item.kato} - ${
                            foundRegion ? foundRegion.name_ru : ""
                        }`,
                    };
                });
            } else {
                this.regionsSnp = [];
            }
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка загрузки данных регионов СНП",
                    error.toString()
                );
            }
        }
    }

    private async getFundingSrc() {
        try {
            const response = await axios.get("api-py/dict_funding_source");
            this.fundingSrcList = response.data;
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка при получении справочника видов источников финансирования",
                    error.toString()
                );
            }
        }
    }

    private async getProjectBranches() {
        try {
            const response = await axios.get("api-py/dict_project_branch");
            this.branchList = response.data;
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка при получении справочника отраслей",
                    error.toString()
                );
            }
        }
    }

    private async getProjectPrg() {
        try {
            const response = await axios.get("api-py/dict_program");
            this.projectPrgList = response.data;
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка при получении типов проектов",
                    error.toString()
                );
            }
        }
    }

    private async getUnits() {
        try {
            const response = await axios.get("api-py/get-dict-unit-all");
            this.unitList = response.data.map((unit: any) => ({
                code: unit.code,
                text: `${unit.name_ru}${
                    unit.national_symbol ? ` (${unit.national_symbol})` : ""
                }`,
            }));
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка при получении единиц измерения",
                    error.toString()
                );
            }
        }
    }

    private openFilterByRef(refName: string) {
        const drop: any = this.$refs.drop;
        drop.show(true);
        let refItem: any;
        switch (refName) {
            case "code":
                refItem = this.$refs.code;
                break;
            case "region":
                refItem = this.$refs.region;
                break;
            case "abp":
                refItem = this.$refs.abp;
                break;
            case "prg":
                refItem = this.$refs.prg;
                break;
            case "snpReg":
                refItem = this.$refs.snpReg;
                break;
            case "period":
                refItem = this.$refs.period;
                break;
            case "status":
                refItem = this.$refs.status;
                break;
        }
        setTimeout(() => refItem.$el.focus(), 100);
    }

    private clickLoadBtn() {
        this.isAddModalVisible = true;
    }

    private async createNewProject() {
        this.resetGipData();
        this.validationErrors = {};
        this.$root.$emit("bv::hide::modal", "add-project-modal");
        this.mode = "create";
        this.showDetailForm = true;
        this.backList = false;
        if (this.selectedCode && this.tempSelectedCode) {
            this.resetFilterData();
        }
        this.selectedBranch = null;
        this.selectedFunding = null;
        this.selectedProjectPrg = null;
        this.selectedUnit = null;
        this.resetFilterData();
    }

    private addProject() {
        this.$root.$emit("bv::hide::modal", "add-project-modal");
        this.mode = "view";
        console.log("INGIP added successfully by integration"); //will be added method gettoing imported GIP
    }

    private async getDetailProjectInfo(item: any) {
        try {
            const response = await fetch(
                `/api-py/get-project-bank-details/${item.id}`
            );
            const projectDetails = await response.json();
            const regionName =
                this.regionList.find(
                    (region: any) =>
                        region.code === projectDetails.project.budget_region
                )?.text || "";
            let abpName =
                this.abpList.find(
                    (abp: any) => abp.abp === projectDetails.project.abp
                )?.text || "";

            if (!abpName && projectDetails.project.budget_region) {
                const abpResponse = await fetch(
                    `/api-py/get-abps-by-region/${projectDetails.project.budget_region}`
                );
                const abpData = await abpResponse.json();
                const abp = abpData.find(
                    (abp: any) => abp.abp === projectDetails.project.abp
                );
                abpName = abp ? abp.text : "";
            }
            let prgName =
                this.prgList.find(
                    (prg: any) => prg.prg === projectDetails.project.prg
                )?.text || "";

            if (!prgName && projectDetails.project.abp) {
                const prgResponse = await fetch(
                    `/api-py/actual-prg-by-abp/${projectDetails.project.abp}`
                );
                const prgData = await prgResponse.json();
                const program = prgData.find(
                    (prg: any) => prg.prg === projectDetails.project.prg
                );
                prgName = program ? `${program.prg} - ${program.name_ru}` : "";
            }

            const locationName =
                this.regionsSnp.find(
                    (region: any) =>
                        region.code === projectDetails.project.location
                )?.text || "";
            const unitName =
                this.unitList.find(
                    (item: any) => item.code === projectDetails.project.unit
                )?.text || "";
            const branchName =
                this.branchList.find(
                    (item: any) => item.code === projectDetails.project.branch
                )?.name_ru || "";
            const projectPrgName =
                this.projectPrgList.find(
                    (item: any) => item.code === projectDetails.project.concept
                )?.name_ru || "";
            const fundingName =
                this.fundingSrcList.find(
                    (item: any) => item.code === projectDetails.project.funding
                )?.name_ru || "";

            this.gipData = {
                ...this.gipData,
                id: projectDetails.project.id,
                code: projectDetails.project.code || this.gipData.code,
                nameRu: projectDetails.project.name_ru || this.gipData.nameRu,
                nameKk: projectDetails.project.name_kz || this.gipData.nameKk,
                projectStatus:
                    projectDetails.project.is_archive === true
                        ? "archived"
                        : "active",
                budgetRegion: regionName || this.gipData.budgetRegion,
                abp: abpName || this.gipData.abp,
                prg: prgName || this.gipData.prg,
                dateFromForInput:
                    new Date(projectDetails.project.begin_date) ||
                    this.gipData.dateFromForInput,
                dateToForInput:
                    new Date(projectDetails.project.end_date) ||
                    this.gipData.dateToForInput,
                location: locationName || this.gipData.location,
                republicanBudgetAmount:
                    projectDetails.project.amount_rep_budget ||
                    this.gipData.republicanBudgetAmount,
                localBudgetAmount:
                    projectDetails.project.amount_local_budget ||
                    this.gipData.localBudgetAmount,
                srcFunding: fundingName || this.gipData.srcFunding,
                srcData:
                    projectDetails.project.project_type === "development"
                        ? "eAkimat"
                        : "ИСГП",
                projectBranch: branchName || this.gipData.projectBranch,
                projectConcept: projectPrgName || this.gipData.projectConcept,
                targetRu:
                    projectDetails.project.purpose_ru || this.gipData.targetRu,
                taskRu:
                    projectDetails.project.task_name_ru || this.gipData.taskRu,
                resultRu:
                    projectDetails.project.result_ru || this.gipData.resultRu,
                targetIndicatorRu:
                    projectDetails.project.indicator_ru ||
                    this.gipData.targetIndicatorRu,
                directResRu:
                    projectDetails.project.direct_result_ru ||
                    this.gipData.directResRu,
                finalResRu:
                    projectDetails.project.final_result_ru ||
                    this.gipData.finalResRu,
                keyIndicatorRu:
                    projectDetails.project.key_indicator_ru ||
                    this.gipData.keyIndicatorRu,
                targetKk:
                    projectDetails.project.purpose_kz || this.gipData.targetKk,
                taskKk:
                    projectDetails.project.task_name_kz || this.gipData.taskKk,
                resultKk:
                    projectDetails.project.result_kz || this.gipData.resultKk,
                targetIndicatorKk:
                    projectDetails.project.indicator_kz ||
                    this.gipData.targetIndicatorKk,
                directResKk:
                    projectDetails.project.direct_result_kz ||
                    this.gipData.directResKk,
                finalResKk:
                    projectDetails.project.final_result_kz ||
                    this.gipData.finalResKk,
                keyIndicatorKk:
                    projectDetails.project.key_indicator_kz ||
                    this.gipData.keyIndicatorKk,
                unit: unitName || this.gipData.unit,
                plan:
                    projectDetails.plan_values.map((pv: any) => ({
                        year: pv.year,
                        value: pv.value,
                    })) || this.gipData.plan,
            };
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка при загрузки детальных данных проекта",
                    error.message
                );
            }
        }
    }

    resetGipData() {
        this.gipData = new GipDataClass();
        this.selectedRegion = this.regionList[0];
        this.selectedAbp = null;
        this.selectedPrg = null;
        this.selectedRegSnp = null;
        this.selectedBranch = null;
        this.selectedFunding = null;
        this.selectedProjectPrg = null;
        this.selectedUnit = null;
        this.isInvestActive = true;
        this.isInvestArchived = true;
        this.selectedFromYear = new Date().getFullYear();
        this.selectedToYear = new Date().getFullYear();
    }

    private async openProject(item: any) {
        await this.getDetailProjectInfo(item);
        this.originalGipData = { ...this.gipData };
        this.isGipEditing = false;
        this.showDetailForm = true;
        this.backList = false;
        this.mode = "view";
    }

    private checkIfEdited(): boolean {
        const updatedData = {
            ...this.gipData,
            abp: this.selectedAbp?.text || this.gipData.abp,
            projectBranch:
                this.selectedBranch?.name_ru || this.gipData.projectBranch,
            prg: this.selectedPrg?.text || this.gipData.prg,
            srcFunding:
                this.selectedFunding?.name_ru || this.gipData.srcFunding,
            projectConcept:
                this.selectedProjectPrg?.name_ru || this.gipData.projectConcept,
            unit: this.selectedUnit?.text || this.gipData.unit,
            location: this.selectedRegSnp?.text || this.gipData.location,
        };

        const isEdited =
            JSON.stringify(updatedData) !==
            JSON.stringify(this.originalGipData);
        if (this.mode === "create") {
            const hasFilledFields =
                !!updatedData.nameRu?.trim() || !!updatedData.nameKk?.trim();
            return hasFilledFields;
        }
        this.isEdited = isEdited;
        return isEdited;
    }

    private openCloseModal() {
        const edited = this.checkIfEdited();
        if (
            edited &&
            (this.mode === "edit" ||
                (!this.isGipSaving && this.mode === "create"))
        ) {
            this.showCloseModal = true;
        } else {
            this.backToProjectList();
        }
    }

    private backToProjectList() {
        this.showDetailForm = false;
        this.backList = true;
        this.isGipSaving = false;
        this.isGipEditing = false;
        this.resetGipData();
        this.changeData();
        this.showCloseModal = false;
        this.isEdited = false;
        this.validationErrors = {};
    }

    @Watch("activeTab")
    private onActiveTabChange(newTab: number, oldTab: number) {
        if (newTab !== oldTab) {
            this.backToProjectList();
        }
    }

    private validationErrors: any = {};

    private validateFieldLength(
        fieldKey: string,
        maxLength: number,
        fieldName: string
    ) {
        const fieldValue = eval(`this.${fieldKey}`);
        const textLength = Array.from(fieldValue).length;

        if (textLength > maxLength) {
            this.validationErrors[fieldKey] = true;
            this.makeToast(
                "warning",
                `${fieldName} превышает допустимый лимит в ${maxLength} символов!`,
                "Внимание!"
            );
            return false;
        }
        return true;
    }

    private validatePositiveSum(fieldKey: string, fieldName: string) {
        const fieldValue = eval(`this.${fieldKey}`);

        if (fieldValue && fieldValue < 0) {
            this.validationErrors[fieldKey] = true;
            this.makeToast(
                "danger",
                `Вводимое значение в поле ${fieldName} должно быть положительным числом!`,
                "Ошибка сохранения"
            );
            return false;
        }
    }

    handleValidationComplete(errors: any) {
        this.validationErrors = errors;
    }

    private validateForm() {
        this.validationErrors = {};
        const statusActive: boolean = this.gipData.projectStatus === "active";
        const isPlanValid = this.$refs.gipCard.validatePlanValues(statusActive);

        const isLengthValid = this.validateFieldLengths();

        if (!isLengthValid) {
            return false;
        }

        const requiredFields = [
            { key: "gipData.nameKk", message: "Название на казахском языке" },
            { key: "gipData.nameRu", message: "Название на русском языке" },
            {
                key:
                    this.mode === "edit"
                        ? "gipData.budgetRegion"
                        : "selectedRegion?.code",
                message: "Бюджетный регион",
            },
            {
                key: this.mode === "edit" ? "gipData.abp" : "selectedAbp?.abp",
                message: "АБП",
            },
            {
                key:
                    this.mode === "edit"
                        ? this.gipData.prg
                            ? "gipData.prg"
                            : "selectedPrg?.text"
                        : "selectedPrg?.prg",
                message: "Программа",
            },
            {
                key:
                    this.mode === "edit"
                        ? "gipData.projectBranch"
                        : "selectedBranch?.code",
                message: "Отрасль",
            },

            { key: "gipData.dateFromForInput", message: "Дата начала" },
            { key: "gipData.dateToForInput", message: "Дата окончания" },
        ];

        for (const field of requiredFields) {
            const value = eval(`this.${field.key}`);

            if (!value || (typeof value === "string" && value.trim() === "")) {
                this.validationErrors[field.key] = true;
                this.makeToast(
                    "danger",
                    `Пожалуйста, заполните поле: ${field.message}`,
                    "Ошибка сохранения"
                );
            } else {
                this.validationErrors[field.key] = false;
            }
        }

        return Object.keys(this.validationErrors).every(
            (key) => !this.validationErrors[key]
        );
    }

    private validateFieldLengths(): boolean {
        const textLimit255 = 255;
        const textLimit500 = 500;

        const validations = [
            this.validateFieldLength(
                "gipData.nameKk",
                textLimit255,
                "Наименование проекта на казахском"
            ),
            this.validateFieldLength(
                "gipData.nameRu",
                textLimit255,
                "Наименование проекта на русском"
            ),
            this.validateFieldLength(
                "gipData.taskKk",
                textLimit500,
                "Задача на казахском"
            ),
            this.validateFieldLength(
                "gipData.taskRu",
                textLimit500,
                "Задача на русском"
            ),
            this.validateFieldLength(
                "gipData.resultKk",
                textLimit500,
                "Результат на казахском"
            ),
            this.validateFieldLength(
                "gipData.resultRu",
                textLimit500,
                "Результат на русском"
            ),
            this.validateFieldLength(
                "gipData.targetKk",
                textLimit500,
                "Целевой индикатор на казахском"
            ),
            this.validateFieldLength(
                "gipData.targetRu",
                textLimit500,
                "Целевой индикатор на русском"
            ),
            this.validateFieldLength(
                "gipData.directResKk",
                textLimit500,
                "Прямой результат на казахском"
            ),
            this.validateFieldLength(
                "gipData.directResRu",
                textLimit500,
                "Прямой результат на русском"
            ),
            this.validateFieldLength(
                "gipData.finalResKk",
                textLimit500,
                "Конечный результат на казахском"
            ),
            this.validateFieldLength(
                "gipData.finalResRu",
                textLimit500,
                "Конечный результат на русском"
            ),
            this.validateFieldLength(
                "gipData.keyIndicatorKk",
                textLimit500,
                "Ключевой показатель на казахском"
            ),
            this.validateFieldLength(
                "gipData.keyIndicatorRu",
                textLimit500,
                "Ключевой показатель на русском"
            ),
            this.validatePositiveSum(
                "gipData.localBudgetAmount",
                "Сумма местного бюджета"
            ),
            this.validatePositiveSum(
                "gipData.republicanBudgetAmount",
                "Сумма республиканского бюджета"
            ),
        ];

        return !validations.includes(false);
    }

    private async saveProjectData() {
        if (!this.validateForm()) {
            return;
        }
        this.isGipSaving = true;
        try {
            const formData = (this.$refs.gipCard as any).getFormData();

            const response = await axios.post(
                "/api-py/save-project-bank-data",
                formData
            );
            if (response.status === 200) {
                const { result, message } = response.data;

                if (result === "success") {
                    this.$emit("messageSent", response.data);
                    this.makeToast(
                        "success",
                        "Запись успешно сохранена.",
                        "Сохранено"
                    );

                    this.isGipEditing = false;
                    this.mode = "view";
                    this.isEdited = false;
                    await this.getDetailProjectInfo({ id: response.data.id });
                    this.getInvestProjectList();
                    // console.log("this.mode", this.mode);
                    // console.log("this.isGipEditing", this.isGipEditing);
                    // console.log("this.isGipSaving", this.isGipSaving);
                } else {
                    throw new Error(message || "Неизвестная ошибка");
                }
            } else {
                throw new Error(`Ошибка сервера: ${response.status}`);
            }
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка при сохранении данных",
                    error.message
                );
            }
        }
    }

    private async changeData() {
        try {
            this.selectedRegion = this.tempSelectedRegion;
            this.selectedAbp = this.tempSelectedAbp;
            this.selectedPrg = this.tempSelectedPrg;
            this.selectedRegSnp = this.tempSelectedRegSnp;
            this.selectedCode = this.tempSelectedCode;
            this.selectedFromYear = this.tempSelectedFromYear;
            this.selectedToYear = this.tempSelectedToYear;
            this.isInvestActive = this.tempIsInvestActive;
            this.isInvestArchived = this.tempIsInvestArchived;
            await this.getInvestProjectList();
            this.$refs.drop.hide(true);
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка при получении проектов",
                    error.toString()
                );
            }
        }
    }

    private resetFilterData() {
        this.tempSelectedRegion = this.regionList[0];
        this.tempSelectedAbp = null;
        this.tempSelectedPrg = null;
        this.prgList = [];
        this.tempSelectedRegSnp = null;
        this.tempIsInvestActive = true;
        this.tempIsInvestArchived = true;
        this.tempSelectedFromYear = new Date().getFullYear();
        this.tempSelectedToYear = new Date().getFullYear();
        this.tempSelectedCode = null;
    }

    private resetFiltersForCode() {
        this.tempSelectedRegion = null;
        this.tempSelectedAbp = null;
        this.tempSelectedPrg = null;
        this.tempSelectedRegSnp = null;
        this.tempIsInvestActive = false;
        this.tempIsInvestArchived = false;
        this.tempSelectedFromYear = 0;
        this.tempSelectedToYear = 0;
    }

    private confirmDeleteProject(item: GipDataClass) {
        this.selectedProject = item;
        this.showDelModal = true;
    }

    private async deleteProject() {
        if (this.selectedProject) {
            try {
                const id = this.selectedProject.id;
                const response = await axios.delete(
                    "api-py/delete-project-banks-item",
                    {
                        data: { id },
                    }
                );

                if (response.status === 200) {
                    const { result, message } = response.data;

                    if (result === "success") {
                        this.$emit("messageSent", response.data);
                        this.makeToast(
                            "success",
                            "Проект успешно удален.",
                            "Сообщение"
                        );
                        await this.getInvestProjectList();
                    } else {
                        throw new Error(message || "Неизвестная ошибка");
                    }
                } else {
                    throw new Error(`Ошибка сервера: ${response.status}`);
                }
            } catch (error) {
                if (error instanceof Error) {
                    this.makeToast(
                        "danger",
                        "Ошибка при удалении проекта",
                        error.toString()
                    );
                }
            }
        }
        this.showDelModal = false;
        await this.getCodeList();
    }

    private cancelDelete() {
        this.showDelModal = false;
        this.selectedProject = null;
    }

    private async getPrgForGipData() {
        const [code] = this.gipData.abp.split(" - ");

        try {
            const response = await fetch(`/api-py/actual-prg-by-abp/${code}`);

            if (!response.ok) {
                throw new Error("Ошибка при получении данных");
            }

            const prgData = await response.json();
            const formattedPrgData = prgData.map((item: any) => ({
                ...item,
                text: `${item.prg} - ${item.name_ru}`,
            }));
            this.prgList = formattedPrgData;
        } catch (error) {
            console.error("Ошибка при загрузке программы:", error);
        }
    }

    private editProjectData() {
        this.mode = "edit";
        this.isGipEditing = true;
        this.isGipSaving = false;
        this.getPrgForGipData();
    }

    private async downloadInfo() {
        if (this.loading) {
            this.makeToast(
                "info",
                "Внимание",
                "Скоро начнется скачивание. Пожалуйста ожидайте."
            );
        } else {
            this.loading = true;
            await axios({
                method: "post",
                url: "/api-py/get-info/37",
                responseType: "blob",
                data: null,
            })
                .then((response) => response.data)
                .then((data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement("a");
                    link.href = url;
                    const titleOfFile =
                        "Инструкция_Банк_Проектов_Проекты_Развития";
                    link.setAttribute("download", titleOfFile + ".pdf");
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    console.log(error);
                    this.makeToast(
                        "danger",
                        "Ошибка запроса руководства пользователя",
                        error.toString()
                    );
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }

    private async downloadProjectList() {
        try {
            const response = await axios.get(
                `/api-py/download-project-bank-list/${this.userId}`,
                {
                    params: {
                        ...(this.selectedCode && { code: this.selectedCode }),
                        obl: this.oblCode,
                        ...(this.selectedRegion && {
                            budget_region: this.selectedRegion.code,
                        }),
                        ...(this.selectedAbp && { abp: this.selectedAbp.abp }),
                        ...(this.selectedPrg && { prg: this.selectedPrg.prg }),
                        ...(this.selectedRegSnp && {
                            location: this.selectedRegSnp.code,
                        }),
                        ...(this.selectedFromYear && {
                            begin_date: `${this.selectedFromYear}-01-01`,
                        }),
                        ...(this.selectedToYear && {
                            end_date: `${this.selectedToYear}-12-31`,
                        }),
                        project_type: "development,imported",
                        ...(this.isInvestActive === true && {
                            is_archive: false,
                        }),
                        ...(this.isInvestArchived === true && {
                            is_archive: true,
                        }),
                        ...(this.isInvestActive === true &&
                            this.isInvestArchived === true && {
                                is_archive: "false,true",
                            }),
                    },
                    responseType: "blob",
                }
            );
            const contentDisposition = response.headers["content-disposition"];
            let filename = "Проект.xlsx"; // Значение по умолчанию

            if (contentDisposition) {
                const match = contentDisposition.match(
                    /filename\*?=['"]?UTF-8['"]?''([^;]*)/
                );
                if (match && match[1]) {
                    filename = decodeURIComponent(match[1]); // Декодир UTF-8 имя файла
                }
            }
            const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка при скачивании файла",
                    error.toString()
                );
            }
        }
    }

    private formatNumber(value: number): string {
        return new Intl.NumberFormat("ru-RU", {
            style: "decimal",
            maximumFractionDigits: 1,
            minimumFractionDigits: 1,
        }).format(value);
    }

    makeToast(variant: string | undefined, tostbody: any, title: string) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: "b-toaster-top-center",
            autoHideDelay: 2000,
            solid: true,
            appendToast: true,
        });
    }
}
