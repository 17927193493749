var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container form-card"},[_c('b-row',[_c('b-col',{staticClass:"form-card-left-col",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Код проекта"}},[_c('b-form-input',{attrs:{"type":"text","value":_vm.tp.code,"disabled":true}})],1),_c('b-form-group',{staticClass:"no-line",attrs:{"label":"Наименование проекта на казахском"}},[_c('b-form-textarea',{class:{
                        'invalid-field': _vm.validationErrors['tpData.nameKk'],
                    },attrs:{"id":"tp-nameKk","disabled":_vm.isViewable || _vm.save},model:{value:(_vm.tp.nameKk),callback:function ($$v) {_vm.$set(_vm.tp, "nameKk", $$v)},expression:"tp.nameKk"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[(_vm.activeTab === 1)?_c('b-form-group',{attrs:{"label":"Тип расходов"}},[(
                        _vm.isViewable ||
                        _vm.isEditable ||
                        (_vm.isCreatable && _vm.tp.srcData)
                    )?_c('b-form-radio-group',{attrs:{"disabled":_vm.isViewable || _vm.isEditable || _vm.save,"name":"prjType"},model:{value:(_vm.tp.srcData),callback:function ($$v) {_vm.$set(_vm.tp, "srcData", $$v)},expression:"tp.srcData"}},[_c('b-form-radio',{attrs:{"value":"current"}},[_vm._v("Текущие проекты")]),_c('b-form-radio',{attrs:{"value":"other"}},[_vm._v("Другие статьи расходов")])],1):_vm._e(),(_vm.isCreatable && !_vm.tp.srcData)?_c('b-form-radio-group',{attrs:{"disabled":_vm.isViewable || _vm.isEditable || _vm.save,"name":"prjType"},model:{value:(_vm.currentType),callback:function ($$v) {_vm.currentType=$$v},expression:"currentType"}},[_c('b-form-radio',{attrs:{"value":"current"}},[_vm._v("Текущие проекты")]),_c('b-form-radio',{attrs:{"value":"other"}},[_vm._v("Другие статьи расходов")])],1):_vm._e()],1):_vm._e(),_c('b-form-group',{staticClass:"no-line",attrs:{"label":"Наименование проекта на русском"}},[_c('b-form-textarea',{class:{
                        'invalid-field': _vm.validationErrors['tpData.nameRu'],
                    },attrs:{"id":"tp-nameRu","disabled":_vm.isViewable || _vm.save},model:{value:(_vm.tp.nameRu),callback:function ($$v) {_vm.$set(_vm.tp, "nameRu", $$v)},expression:"tp.nameRu"}})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{staticClass:"form-card-left-col",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{staticClass:"h-50px",attrs:{"label":"Регион"}},[(_vm.isViewable)?[_c('b-form-input',{attrs:{"type":"text","disabled":true},model:{value:(_vm.tp.budgetRegion),callback:function ($$v) {_vm.$set(_vm.tp, "budgetRegion", $$v)},expression:"tp.budgetRegion"}})]:[_c('multiselect',{staticClass:"fixed-width-multiselect",class:{
                            'invalid-field':
                                _vm.validationErrors['selectedRegion?.code'],
                        },attrs:{"options":_vm.localRegionOptions,"track-by":"code","label":"text","placeholder":"Регион","allow-empty":true,"show-labels":false,"disabled":_vm.isEditable || _vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.currentRegion),callback:function ($$v) {_vm.currentRegion=$$v},expression:"currentRegion"}})]],2),_c('b-form-group',{staticClass:"h-50px",attrs:{"label":"АБП"}},[(_vm.isViewable)?[_c('b-form-input',{attrs:{"type":"text","disabled":_vm.isViewable},model:{value:(_vm.tp.abp),callback:function ($$v) {_vm.$set(_vm.tp, "abp", $$v)},expression:"tp.abp"}})]:[_c('multiselect',{staticClass:"fixed-width-multiselect",class:{
                            'invalid-field':
                                _vm.validationErrors['selectedAbp?.abp'],
                        },attrs:{"options":_vm.localAbpOptions,"track-by":"abp","label":"text","placeholder":"АБП","allow-empty":false,"show-labels":false,"disabled":_vm.save},model:{value:(_vm.currentAbp),callback:function ($$v) {_vm.currentAbp=$$v},expression:"currentAbp"}})]],2),(_vm.activeTab === 1)?_c('b-form-group',{staticClass:"h-50px",attrs:{"label":"Статус проекта"}},[_c('b-form-radio-group',{attrs:{"disabled":_vm.isViewable || _vm.save,"name":"prjStatus","label":"Статус проекта"},model:{value:(_vm.tp.projectStatus),callback:function ($$v) {_vm.$set(_vm.tp, "projectStatus", $$v)},expression:"tp.projectStatus"}},[_c('b-form-radio',{attrs:{"value":"active"}},[_vm._v("Действующий")]),_c('b-form-radio',{attrs:{"value":"archived"}},[_vm._v("Архивный")])],1)],1):_vm._e(),_c('b-form-group',{staticClass:"h-50px",attrs:{"label":"Источник финансирования"}},[(_vm.isViewable)?[_c('b-form-input',{attrs:{"type":"text","value":_vm.tp.srcFunding ? _vm.tp.srcFunding : '',"disabled":true}})]:[_c('multiselect',{staticClass:"fixed-width-multiselect",class:{
                            'invalid-field':
                                _vm.validationErrors['selectedFunding?.code'],
                        },attrs:{"options":_vm.localFundingSrcOptions,"track-by":"code","label":"name_ru","placeholder":"Выбрать источник финансирования","allow-empty":false,"show-labels":false,"disabled":_vm.save},model:{value:(_vm.currentFundingSrc),callback:function ($$v) {_vm.currentFundingSrc=$$v},expression:"currentFundingSrc"}})]],2),_c('b-form-group',{staticClass:"h-50px",attrs:{"label":"Выделенная сумма из РБ"}},[_c('div',{staticClass:"d-flex align-items-center"},[(_vm.isViewable)?[_c('b-form-input',{staticStyle:{"width":"150px"},attrs:{"type":"text","value":_vm.formatNumber(_vm.tp.republicanBudgetAmount),"disabled":true}})]:[_c('b-form-input',{class:{
                                'invalid-field':
                                    _vm.validationErrors[
                                        'tpData.republicanBudgetAmount'
                                    ] ||
                                    _vm.tp.republicanBudgetAmount ===
                                        undefined ||
                                    _vm.tp.republicanBudgetAmount < 0,
                            },staticStyle:{"width":"150px"},attrs:{"type":"number","disabled":_vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.tp.republicanBudgetAmount),callback:function ($$v) {_vm.$set(_vm.tp, "republicanBudgetAmount", _vm._n($$v))},expression:"tp.republicanBudgetAmount"}})],_c('span',{staticClass:"ml-2 sum-text"},[_vm._v("тыс.тенге")])],2)]),_c('b-form-group',{staticClass:"h-50px",attrs:{"label":"Выделенная сумма из МБ"}},[_c('div',{staticClass:"d-flex align-items-center"},[(_vm.isViewable)?[_c('b-form-input',{staticStyle:{"width":"150px"},attrs:{"type":"text","value":_vm.formatNumber(_vm.tp.localBudgetAmount),"disabled":true}})]:[_c('b-form-input',{class:{
                                'invalid-field':
                                    _vm.validationErrors[
                                        'tpData.localBudgetAmount'
                                    ] ||
                                    _vm.tp.localBudgetAmount === undefined ||
                                    _vm.tp.localBudgetAmount < 0,
                            },staticStyle:{"width":"150px"},attrs:{"type":"number","disabled":_vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.tp.localBudgetAmount),callback:function ($$v) {_vm.$set(_vm.tp, "localBudgetAmount", _vm._n($$v))},expression:"tp.localBudgetAmount"}})],_c('span',{staticClass:"ml-2 sum-text"},[_vm._v("тыс.тенге")])],2)])],1),_c('b-col',{staticClass:"pr-0"},[_c('b-form-group',{staticClass:"h-50px",attrs:{"label":"Отрасль проекта"}},[(_vm.isViewable)?[_c('b-form-input',{attrs:{"type":"text","value":_vm.tp.projectBranch ? _vm.tp.projectBranch : '',"disabled":true}})]:[_c('multiselect',{staticClass:"fixed-width-multiselect",class:{
                            'invalid-field':
                                _vm.validationErrors['selectedBranch?.code'],
                        },attrs:{"options":_vm.localBranchOptions,"track-by":"code","label":"name_ru","placeholder":"Выбрать отрасль","allow-empty":false,"show-labels":false,"disabled":_vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.currentBranch),callback:function ($$v) {_vm.currentBranch=$$v},expression:"currentBranch"}})]],2),_c('b-form-group',{staticClass:"h-50px",attrs:{"label":"Программа"}},[(_vm.isViewable)?[_c('b-form-input',{attrs:{"type":"text","value":_vm.tp.prg,"disabled":true}})]:[_c('multiselect',{staticClass:"fixed-width-multiselect",class:{
                            'invalid-field':
                                _vm.mode === 'edit'
                                    ? _vm.validationErrors['selectedPrg?.text']
                                    : _vm.validationErrors['selectedPrg?.prg'],
                        },attrs:{"options":_vm.localPrgOptions,"track-by":"prg","label":"text","placeholder":"Выбрать программу","allow-empty":true,"show-labels":false,"disabled":_vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.currentPrg),callback:function ($$v) {_vm.currentPrg=$$v},expression:"currentPrg"}})]],2),_c('b-form-group',{staticClass:"h-100px",attrs:{"label":"Программа, региональный проект, национальный проект, концепция"}},[(_vm.isViewable)?[_c('b-form-input',{staticClass:"no-line",attrs:{"value":_vm.tp.projectConcept ? _vm.tp.projectConcept : '',"disabled":true,"type":"text"}})]:[_c('multiselect',{class:{
                            'invalid-field':
                                _vm.mode === 'edit'
                                    ? _vm.validationErrors[
                                          'selectedProjectPrg?.name_ru'
                                      ]
                                    : _vm.validationErrors[
                                          'selectedProjectPrg?.code'
                                      ],
                        },attrs:{"options":_vm.localProjectPrgOptions,"track-by":"code","label":"name_ru","placeholder":"Выбрать тип программы","allow-empty":true,"show-labels":false,"disabled":_vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.currentProjectPrg),callback:function ($$v) {_vm.currentProjectPrg=$$v},expression:"currentProjectPrg"}})]],2),_c('b-form-group',{staticClass:"h-50px",attrs:{"label":"Период реализации"}},[(_vm.isViewable)?_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"mr-2 year-text"},[_vm._v("с")]),_c('b-form-input',{staticClass:"year-input-style mr-2",attrs:{"type":"text","value":_vm.formatDateToMMYYYY(_vm.tp.dateFromForInput),"disabled":_vm.isViewable || _vm.save}}),_c('span',{staticClass:"mr-2 year-text"},[_vm._v("по")]),_c('b-form-input',{staticClass:"year-input-style",attrs:{"type":"text","value":_vm.formatDateToMMYYYY(_vm.tp.dateToForInput),"disabled":_vm.isViewable}})],1):_c('div',{staticClass:"date-range"},[_c('label',{staticClass:"mr-3 year-text"},[_vm._v("с")]),_c('date-picker',{class:{
                            'invalid-field':
                                _vm.validationErrors['tpData.dateFromForInput'],
                        },attrs:{"placeholder":"Выберите период","type":"month","format":"MM/YYYY","disabled-date":_vm.disableDate,"disabled":_vm.isViewable || _vm.save},on:{"change":function($event){return _vm.validateDateRange('from')}},model:{value:(_vm.tp.dateFromForInput),callback:function ($$v) {_vm.$set(_vm.tp, "dateFromForInput", $$v)},expression:"tp.dateFromForInput"}}),_c('label',{staticClass:"mx-3 year-text"},[_vm._v("по")]),_c('date-picker',{class:{
                            'invalid-field':
                                _vm.validationErrors['tpData.dateToForInput'],
                        },attrs:{"placeholder":"Выберите период","type":"month","format":"MM/YYYY","disabled-date":_vm.disableDate,"disabled":_vm.isViewable || _vm.save},on:{"change":function($event){return _vm.validateDateRange('to')}},model:{value:(_vm.tp.dateToForInput),callback:function ($$v) {_vm.$set(_vm.tp, "dateToForInput", $$v)},expression:"tp.dateToForInput"}})],1)]),_c('b-form-group',{staticClass:"no-line",attrs:{"label":"Место реализации"}},[(_vm.isViewable)?[_c('b-form-input',{attrs:{"type":"text","value":_vm.tp.location,"disabled":true}})]:[_c('multiselect',{staticClass:"fixed-width-multiselect",class:{
                            'invalid-field':
                                _vm.validationErrors['selectedRegSnp?.code'],
                        },attrs:{"options":_vm.localKatoOptions,"track-by":"code","label":"text","placeholder":"Выбрать место реализации","allow-empty":false,"show-labels":false,"disabled":_vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.currentKato),callback:function ($$v) {_vm.currentKato=$$v},expression:"currentKato"}})]],2)],1)],1),_c('hr'),_vm._m(0),_c('b-table',{staticClass:"gip-card-table",attrs:{"items":_vm.formattedPlanData,"fields":_vm.tableFields,"bordered":""},scopedSlots:_vm._u([_vm._l((_vm.tableFields),function(field){return {key:("cell(" + (field.key) + ")"),fn:function(data){return [(!_vm.isViewable)?[_c('b-form-input',{key:("editable-" + (field.key)),class:{
                        'invalid-field': _vm.localValidationErrors[field.key],
                    },attrs:{"type":"number","min":"0","placeholder":"Введите значение","disabled":_vm.isViewable || _vm.save},model:{value:(data.item[field.key]),callback:function ($$v) {_vm.$set(data.item, field.key, $$v)},expression:"data.item[field.key]"}})]:[_vm._v(" "+_vm._s(_vm.formatNumber(data.item[field.key]))+" ")]]}}})],null,true)}),_vm._m(1),_c('b-row',{staticClass:"lang-row"},[_c('b-col',{staticClass:"form-card-left-col",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Цель")]),_c('span',{staticClass:"lang-text"},[_vm._v("на казахском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['tpData.targetKk'],
                    },attrs:{"id":"tp-target","disabled":_vm.isViewable || _vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.tp.targetKk),callback:function ($$v) {_vm.$set(_vm.tp, "targetKk", $$v)},expression:"tp.targetKk"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Задача")]),_c('span',{staticClass:"lang-text"},[_vm._v("на казахском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field': _vm.validationErrors['tpData.taskKk'],
                    },attrs:{"id":"tp-task","disabled":_vm.isViewable || _vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.tp.taskKk),callback:function ($$v) {_vm.$set(_vm.tp, "taskKk", $$v)},expression:"tp.taskKk"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Результат")]),_c('span',{staticClass:"lang-text"},[_vm._v("на казахском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['tpData.resultKk'],
                    },attrs:{"id":"tp-res","disabled":_vm.isViewable || _vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.tp.resultKk),callback:function ($$v) {_vm.$set(_vm.tp, "resultKk", $$v)},expression:"tp.resultKk"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Целевой индикатор")]),_c('span',{staticClass:"lang-text"},[_vm._v("на казахском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['tpData.targetIndicatorKk'],
                    },attrs:{"id":"tp-tarind","disabled":_vm.isViewable || _vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.tp.targetIndicatorKk),callback:function ($$v) {_vm.$set(_vm.tp, "targetIndicatorKk", $$v)},expression:"tp.targetIndicatorKk"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Прямой результат")]),_c('span',{staticClass:"lang-text"},[_vm._v("на казахском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['tpData.directResKk'],
                    },attrs:{"id":"tp-dres","disabled":_vm.isViewable || _vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.tp.directResKk),callback:function ($$v) {_vm.$set(_vm.tp, "directResKk", $$v)},expression:"tp.directResKk"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Конечный результат")]),_c('span',{staticClass:"lang-text"},[_vm._v("на казахском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['tpData.finalResKk'],
                    },attrs:{"id":"tp-fres","disabled":_vm.isViewable || _vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.tp.finalResKk),callback:function ($$v) {_vm.$set(_vm.tp, "finalResKk", $$v)},expression:"tp.finalResKk"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Ключевой показатель")]),_c('span',{staticClass:"lang-text"},[_vm._v("на казахском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['tpData.keyIndicatorKk'],
                    },attrs:{"id":"tp-keyind","disabled":_vm.isViewable || _vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.tp.keyIndicatorKk),callback:function ($$v) {_vm.$set(_vm.tp, "keyIndicatorKk", $$v)},expression:"tp.keyIndicatorKk"}})],1),_c('b-form-group',{attrs:{"label-cols":"3","label":"Единица измерения"}},[(_vm.isViewable)?[_c('b-form-input',{staticClass:"no-line",attrs:{"type":"text","value":_vm.tp.unit ? _vm.tp.unit : '',"disabled":true}})]:[_c('multiselect',{class:{
                            'invalid-field':
                                _vm.mode === 'edit'
                                    ? _vm.validationErrors['selectedUnit?.text']
                                    : _vm.validationErrors[
                                          'selectedUnit?.code'
                                      ],
                        },attrs:{"options":_vm.localUnitOptions,"track-by":"code","label":"text","placeholder":"Выбрать единицу измерения","allow-empty":true,"show-labels":false,"disabled":_vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.currentUnit),callback:function ($$v) {_vm.currentUnit=$$v},expression:"currentUnit"}})]],2)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Цель")]),_c('span',{staticClass:"lang-text"},[_vm._v("на русском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['tpData.targetRu'],
                    },attrs:{"id":"tp-target","disabled":_vm.isViewable || _vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.tp.targetRu),callback:function ($$v) {_vm.$set(_vm.tp, "targetRu", $$v)},expression:"tp.targetRu"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Задача")]),_c('span',{staticClass:"lang-text"},[_vm._v("на русском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field': _vm.validationErrors['tpData.taskRu'],
                    },attrs:{"id":"tp-task","disabled":_vm.isViewable || _vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.tp.taskRu),callback:function ($$v) {_vm.$set(_vm.tp, "taskRu", $$v)},expression:"tp.taskRu"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3","disabled":_vm.isViewable || _vm.save || _vm.tp.srcData === 'other'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Результат")]),_c('span',{staticClass:"lang-text"},[_vm._v("на русском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['tpData.resultRu'],
                    },attrs:{"id":"tp-res","disabled":_vm.isViewable || _vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.tp.resultRu),callback:function ($$v) {_vm.$set(_vm.tp, "resultRu", $$v)},expression:"tp.resultRu"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3","disabled":_vm.isViewable},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Целевой индикатор")]),_c('span',{staticClass:"lang-text"},[_vm._v("на русском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['tpData.targetIndicatorRu'],
                    },attrs:{"id":"tp-tarind","disabled":_vm.isViewable || _vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.tp.targetIndicatorRu),callback:function ($$v) {_vm.$set(_vm.tp, "targetIndicatorRu", $$v)},expression:"tp.targetIndicatorRu"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3","disabled":_vm.isViewable},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Прямой результат")]),_c('span',{staticClass:"lang-text"},[_vm._v("на русском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['tpData.directResRu'],
                    },attrs:{"id":"tp-dres","disabled":_vm.isViewable || _vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.tp.directResRu),callback:function ($$v) {_vm.$set(_vm.tp, "directResRu", $$v)},expression:"tp.directResRu"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3","disabled":_vm.isViewable},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Конечный результат")]),_c('span',{staticClass:"lang-text"},[_vm._v("на русском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['tpData.finalResRu'],
                    },attrs:{"id":"tp-fres","disabled":_vm.isViewable || _vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.tp.finalResRu),callback:function ($$v) {_vm.$set(_vm.tp, "finalResRu", $$v)},expression:"tp.finalResRu"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3","disabled":_vm.isViewable},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Ключевой показатель")]),_c('span',{staticClass:"lang-text"},[_vm._v("на русском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['tpData.keyIndicatorRu'],
                    },attrs:{"id":"tp-keyind","disabled":_vm.isViewable || _vm.save || _vm.tp.srcData === 'other'},model:{value:(_vm.tp.keyIndicatorRu),callback:function ($$v) {_vm.$set(_vm.tp, "keyIndicatorRu", $$v)},expression:"tp.keyIndicatorRu"}})],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label-block"},[_c('span',{staticClass:"label-style bold pl-0"},[_vm._v("Планируемые значения (тыс. тенге)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label-block"},[_c('span',{staticClass:"label-style bold pl-0"},[_vm._v("Дополнительная инфомация")])])}]

export { render, staticRenderFns }