var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container form-card"},[_c('b-row',[_c('b-col',{staticClass:"form-card-left-col",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Код проекта"}},[_c('b-form-input',{staticClass:"bold",attrs:{"type":"text","value":_vm.gip.code,"disabled":true}})],1),_c('b-form-group',{staticClass:"no-line",attrs:{"label":"Наименование проекта на казахском"}},[_c('b-form-textarea',{class:{
                        'invalid-field': _vm.validationErrors['gipData.nameKk'],
                    },attrs:{"id":"gip-nameKk","disabled":_vm.isViewable || _vm.save},model:{value:(_vm.gip.nameKk),callback:function ($$v) {_vm.$set(_vm.gip, "nameKk", $$v)},expression:"gip.nameKk"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Регион"}},[(_vm.isViewable)?[_c('b-form-input',{attrs:{"type":"text","disabled":true},model:{value:(_vm.gip.budgetRegion),callback:function ($$v) {_vm.$set(_vm.gip, "budgetRegion", $$v)},expression:"gip.budgetRegion"}})]:[_c('multiselect',{staticClass:"fixed-width-multiselect",attrs:{"options":_vm.localRegionOptions,"track-by":"code","label":"text","placeholder":"Регион","allow-empty":false,"show-labels":false,"disabled":_vm.isEditable || _vm.save,"v-bind:class":{
                            'invalid-field':
                                _vm.validationErrors['selectedRegion?.code'],
                        }},model:{value:(_vm.currentRegion),callback:function ($$v) {_vm.currentRegion=$$v},expression:"currentRegion"}})]],2),_c('b-form-group',{staticClass:"no-line",attrs:{"label":"Наименование проекта на русском"}},[_c('b-form-textarea',{class:{
                        'invalid-field': _vm.validationErrors['gipData.nameRu'],
                    },attrs:{"id":"gip-nameRu","disabled":_vm.isViewable || _vm.save},model:{value:(_vm.gip.nameRu),callback:function ($$v) {_vm.$set(_vm.gip, "nameRu", $$v)},expression:"gip.nameRu"}})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{staticClass:"form-card-left-col",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{staticClass:"h-50px",attrs:{"label":"АБП"}},[(_vm.isViewable)?[_c('b-form-input',{attrs:{"type":"text","disabled":_vm.isViewable},model:{value:(_vm.gip.abp),callback:function ($$v) {_vm.$set(_vm.gip, "abp", $$v)},expression:"gip.abp"}})]:[_c('multiselect',{staticClass:"fixed-width-multiselect",class:{
                            'invalid-field':
                                _vm.validationErrors['selectedAbp?.abp'],
                        },attrs:{"options":_vm.localAbpOptions,"track-by":"abp","label":"text","placeholder":"АБП","allow-empty":true,"show-labels":false,"disabled":_vm.save},model:{value:(_vm.currentAbp),callback:function ($$v) {_vm.currentAbp=$$v},expression:"currentAbp"}})]],2),(_vm.activeTab === 0)?_c('b-form-group',{staticClass:"h-50px",attrs:{"label":"Статус проекта"}},[_c('b-form-radio-group',{attrs:{"disabled":_vm.isViewable || _vm.save,"name":"prjStatus","label":"Статус проекта"},model:{value:(_vm.gip.projectStatus),callback:function ($$v) {_vm.$set(_vm.gip, "projectStatus", $$v)},expression:"gip.projectStatus"}},[_c('b-form-radio',{attrs:{"value":"active"}},[_vm._v("Действующий")]),_c('b-form-radio',{attrs:{"value":"archived"}},[_vm._v("Архивный")])],1)],1):_vm._e(),_c('b-form-group',{staticClass:"h-50px",attrs:{"label":"Источник финансирования"}},[(_vm.isViewable)?[_c('b-form-input',{attrs:{"type":"text","value":_vm.gip.srcFunding ? _vm.gip.srcFunding : '',"disabled":true}})]:[_c('multiselect',{staticClass:"fixed-width-multiselect",class:{
                            'invalid-field':
                                _vm.validationErrors['selectedFunding?.code'],
                        },attrs:{"options":_vm.localFundingSrcOptions,"track-by":"code","label":"name_ru","placeholder":"Выбрать источник финансирования","allow-empty":false,"show-labels":false,"disabled":_vm.save},model:{value:(_vm.currentFundingSrc),callback:function ($$v) {_vm.currentFundingSrc=$$v},expression:"currentFundingSrc"}})]],2),_c('b-form-group',{staticClass:"h-50px",attrs:{"label":"Выделенная сумма из РБ"}},[_c('div',{staticClass:"d-flex align-items-center"},[(_vm.isViewable)?[_c('b-form-input',{staticStyle:{"width":"150px"},attrs:{"type":"text","value":_vm.formatNumber(_vm.gip.republicanBudgetAmount),"disabled":true}})]:[_c('b-form-input',{class:{
                                'invalid-field':
                                    _vm.validationErrors[
                                        'gipData.republicanBudgetAmount'
                                    ] ||
                                    _vm.gip.republicanBudgetAmount === null ||
                                    _vm.gip.republicanBudgetAmount ===
                                        undefined ||
                                    _vm.gip.republicanBudgetAmount < 0,
                            },staticStyle:{"width":"150px"},attrs:{"type":"number","disabled":_vm.save},model:{value:(_vm.gip.republicanBudgetAmount),callback:function ($$v) {_vm.$set(_vm.gip, "republicanBudgetAmount", _vm._n($$v))},expression:"gip.republicanBudgetAmount"}})],_c('span',{staticClass:"ml-2 sum-text"},[_vm._v("тыс.тенге")])],2)]),_c('b-form-group',{staticClass:"h-50px",attrs:{"label":"Выделенная сумма из МБ"}},[_c('div',{staticClass:"d-flex align-items-center"},[(_vm.isViewable)?[_c('b-form-input',{staticStyle:{"width":"150px"},attrs:{"type":"text","value":_vm.formatNumber(_vm.gip.localBudgetAmount),"disabled":true}})]:[_c('b-form-input',{class:{
                                'invalid-field':
                                    _vm.validationErrors[
                                        'gipData.localBudgetAmount'
                                    ] ||
                                    _vm.gip.localBudgetAmount === null ||
                                    _vm.gip.localBudgetAmount === undefined ||
                                    _vm.gip.localBudgetAmount < 0,
                            },staticStyle:{"width":"150px"},attrs:{"type":"number","disabled":_vm.save},model:{value:(_vm.gip.localBudgetAmount),callback:function ($$v) {_vm.$set(_vm.gip, "localBudgetAmount", _vm._n($$v))},expression:"gip.localBudgetAmount"}})],_c('span',{staticClass:"ml-2 sum-text"},[_vm._v("тыс.тенге")])],2)]),_c('b-form-group',{staticClass:"h-50px",attrs:{"label":"Источник","disabled":true}},[(_vm.isViewable)?_c('b-form-input',{attrs:{"type":"text","value":_vm.gip.srcData}}):_vm._e(),(!_vm.isViewable)?_c('b-form-input',{attrs:{"type":"text","value":"e-akimat","disabled":true}}):_vm._e()],1)],1),_c('b-col',{staticClass:"pr-0"},[_c('b-form-group',{staticClass:"h-50px",attrs:{"label":"Отрасль проекта"}},[(_vm.isViewable)?[_c('b-form-input',{attrs:{"type":"text","value":_vm.gip.projectBranch ? _vm.gip.projectBranch : '',"disabled":true}})]:[_c('multiselect',{staticClass:"fixed-width-multiselect",class:{
                            'invalid-field':
                                _vm.validationErrors['selectedBranch?.code'],
                        },attrs:{"options":_vm.localBranchOptions,"track-by":"code","label":"name_ru","placeholder":"Выбрать отрасль","allow-empty":false,"show-labels":false,"disabled":_vm.save},model:{value:(_vm.currentBranch),callback:function ($$v) {_vm.currentBranch=$$v},expression:"currentBranch"}})]],2),_c('b-form-group',{staticClass:"h-50px",attrs:{"label":"Программа"}},[(_vm.isViewable)?[_c('b-form-input',{attrs:{"type":"text","value":_vm.gip.prg,"disabled":true}})]:[_c('multiselect',{staticClass:"fixed-width-multiselect",class:{
                            'invalid-field':
                                _vm.mode === 'edit'
                                    ? _vm.validationErrors['selectedPrg?.text']
                                    : _vm.validationErrors['selectedPrg?.prg'],
                        },attrs:{"options":_vm.localPrgOptions,"track-by":"prg","label":"text","placeholder":"Выбрать программу","allow-empty":true,"show-labels":false,"disabled":_vm.save},model:{value:(_vm.currentPrg),callback:function ($$v) {_vm.currentPrg=$$v},expression:"currentPrg"}})]],2),_c('b-form-group',{staticClass:"h-100px",attrs:{"label":"Программа, региональный проект, национальный проект, концепция"}},[(_vm.isViewable)?[_c('b-form-input',{attrs:{"type":"text","value":_vm.gip.projectConcept ? _vm.gip.projectConcept : '',"disabled":true}})]:[_c('multiselect',{staticClass:"fixed-width-multiselect",class:{
                            'invalid-field':
                                _vm.mode === 'edit'
                                    ? _vm.validationErrors[
                                          'selectedProjectPrg?.name_ru'
                                      ]
                                    : _vm.validationErrors[
                                          'selectedProjectPrg?.code'
                                      ],
                        },attrs:{"options":_vm.localProjectPrgOptions,"track-by":"code","label":"name_ru","placeholder":"Выбрать тип программы","allow-empty":true,"show-labels":false,"disabled":_vm.save},model:{value:(_vm.currentProjectPrg),callback:function ($$v) {_vm.currentProjectPrg=$$v},expression:"currentProjectPrg"}})]],2),_c('b-form-group',{staticClass:"h-50px",attrs:{"label":"Период реализации"}},[(_vm.isViewable)?_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"mr-2 year-text"},[_vm._v("с")]),_c('b-form-input',{staticClass:"year-input-style mr-2",attrs:{"type":"text","value":_vm.formatDateToMMYYYY(_vm.gip.dateFromForInput),"disabled":_vm.isViewable || _vm.save}}),_c('span',{staticClass:"mr-2 year-text"},[_vm._v("по")]),_c('b-form-input',{staticClass:"year-input-style",attrs:{"type":"text","value":_vm.formatDateToMMYYYY(_vm.gip.dateToForInput),"disabled":_vm.isViewable}})],1):_c('div',{staticClass:"date-range"},[_c('label',{staticClass:"mr-3 year-text"},[_vm._v("с")]),_c('date-picker',{class:{
                            'invalid-field':
                                _vm.validationErrors[
                                    'gipData.dateFromForInput'
                                ],
                        },attrs:{"placeholder":"Выберите период","type":"month","format":"MM/YYYY","disabled-date":_vm.disableDate,"disabled":_vm.isViewable || _vm.save},on:{"change":function($event){return _vm.validateDateRange('from')}},model:{value:(_vm.gip.dateFromForInput),callback:function ($$v) {_vm.$set(_vm.gip, "dateFromForInput", $$v)},expression:"gip.dateFromForInput"}}),_c('label',{staticClass:"mx-3 year-text"},[_vm._v("по")]),_c('date-picker',{class:{
                            'invalid-field':
                                _vm.validationErrors['gipData.dateToForInput'],
                        },attrs:{"placeholder":"Выберите период","type":"month","format":"MM/YYYY","disabled-date":_vm.disableDate,"disabled":_vm.isViewable || _vm.save},on:{"change":function($event){return _vm.validateDateRange('to')}},model:{value:(_vm.gip.dateToForInput),callback:function ($$v) {_vm.$set(_vm.gip, "dateToForInput", $$v)},expression:"gip.dateToForInput"}})],1)]),_c('b-form-group',{staticClass:"h-50px",attrs:{"label":"Место реализации"}},[(_vm.isViewable)?[_c('b-form-input',{attrs:{"type":"text","value":_vm.gip.location,"disabled":true}})]:[_c('multiselect',{staticClass:"fixed-width-multiselect",class:{
                            'invalid-field':
                                _vm.validationErrors['selectedRegSnp?.code'],
                        },attrs:{"options":_vm.localKatoOptions,"track-by":"code","label":"text","placeholder":"Выбрать место реализации","allow-empty":false,"show-labels":false,"disabled":_vm.save},model:{value:(_vm.currentKato),callback:function ($$v) {_vm.currentKato=$$v},expression:"currentKato"}})]],2)],1)],1),_c('hr'),_vm._m(0),_c('b-table',{staticClass:"gip-card-table",attrs:{"items":_vm.formattedPlanData,"fields":_vm.tableFields,"bordered":""},scopedSlots:_vm._u([_vm._l((_vm.tableFields),function(field){return {key:("cell(" + (field.key) + ")"),fn:function(data){return [(!_vm.isViewable)?[_c('b-form-input',{key:("editable-" + (field.key)),class:{
                        'invalid-field': _vm.localValidationErrors[field.key],
                    },attrs:{"type":"number","placeholder":"Введите значение","min":"0","disabled":_vm.isViewable || _vm.save},model:{value:(data.item[field.key]),callback:function ($$v) {_vm.$set(data.item, field.key, $$v)},expression:"data.item[field.key]"}})]:[_vm._v(" "+_vm._s(_vm.formatNumber(data.item[field.key]))+" ")]]}}})],null,true)}),_vm._m(1),_c('b-row',{staticClass:"lang-row"},[_c('b-col',{staticClass:"form-card-left-col",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Цель")]),_c('span',{staticClass:"lang-text"},[_vm._v("на казахском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['gipData.targetKk'],
                    },attrs:{"id":"gip-target","disabled":_vm.isViewable || _vm.save},model:{value:(_vm.gip.targetKk),callback:function ($$v) {_vm.$set(_vm.gip, "targetKk", $$v)},expression:"gip.targetKk"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Задача")]),_c('span',{staticClass:"lang-text"},[_vm._v("на казахском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field': _vm.validationErrors['gipData.taskKk'],
                    },attrs:{"id":"gip-task","disabled":_vm.isViewable || _vm.save},model:{value:(_vm.gip.taskKk),callback:function ($$v) {_vm.$set(_vm.gip, "taskKk", $$v)},expression:"gip.taskKk"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Результат")]),_c('span',{staticClass:"lang-text"},[_vm._v("на казахском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['gipData.resultKk'],
                    },attrs:{"id":"gip-res","disabled":_vm.isViewable || _vm.save},model:{value:(_vm.gip.resultKk),callback:function ($$v) {_vm.$set(_vm.gip, "resultKk", $$v)},expression:"gip.resultKk"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Целевой индикатор")]),_c('span',{staticClass:"lang-text"},[_vm._v("на казахском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['gipData.targetIndicatorKk'],
                    },attrs:{"id":"gip-tarind","disabled":_vm.isViewable || _vm.save},model:{value:(_vm.gip.targetIndicatorKk),callback:function ($$v) {_vm.$set(_vm.gip, "targetIndicatorKk", $$v)},expression:"gip.targetIndicatorKk"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Прямой результат")]),_c('span',{staticClass:"lang-text"},[_vm._v("на казахском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['gipData.directResKk'],
                    },attrs:{"id":"gip-dres","disabled":_vm.isViewable || _vm.save},model:{value:(_vm.gip.directResKk),callback:function ($$v) {_vm.$set(_vm.gip, "directResKk", $$v)},expression:"gip.directResKk"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Конечный результат")]),_c('span',{staticClass:"lang-text"},[_vm._v("на казахском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['gipData.finalResKk'],
                    },attrs:{"id":"gip-fres","disabled":_vm.isViewable || _vm.save},model:{value:(_vm.gip.finalResKk),callback:function ($$v) {_vm.$set(_vm.gip, "finalResKk", $$v)},expression:"gip.finalResKk"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Ключевой показатель")]),_c('span',{staticClass:"lang-text"},[_vm._v("на казахском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['gipData.keyIndicatorKk'],
                    },attrs:{"id":"gip-keyind","disabled":_vm.isViewable || _vm.save},model:{value:(_vm.gip.keyIndicatorKk),callback:function ($$v) {_vm.$set(_vm.gip, "keyIndicatorKk", $$v)},expression:"gip.keyIndicatorKk"}})],1),_c('b-form-group',{attrs:{"label-cols":"3","label":"Единица измерения"}},[(_vm.isViewable)?[_c('b-form-input',{attrs:{"type":"text","value":_vm.gip.unit ? _vm.gip.unit : '',"disabled":true}})]:[_c('multiselect',{class:{
                            'invalid-field':
                                _vm.mode === 'edit'
                                    ? _vm.validationErrors['selectedUnit?.text']
                                    : _vm.validationErrors[
                                          'selectedUnit?.code'
                                      ],
                        },attrs:{"options":_vm.localUnitOptions,"track-by":"code","label":"text","placeholder":"Выбрать единицу измерения","allow-empty":true,"show-labels":false,"disabled":_vm.save},model:{value:(_vm.currentUnit),callback:function ($$v) {_vm.currentUnit=$$v},expression:"currentUnit"}})]],2)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Цель")]),_c('span',{staticClass:"lang-text"},[_vm._v("на русском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['gipData.targetRu'],
                    },attrs:{"id":"gip-target","disabled":_vm.isViewable || _vm.save},model:{value:(_vm.gip.targetRu),callback:function ($$v) {_vm.$set(_vm.gip, "targetRu", $$v)},expression:"gip.targetRu"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Задача")]),_c('span',{staticClass:"lang-text"},[_vm._v("на русском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field': _vm.validationErrors['gipData.taskRu'],
                    },attrs:{"id":"gip-task","disabled":_vm.isViewable || _vm.save},model:{value:(_vm.gip.taskRu),callback:function ($$v) {_vm.$set(_vm.gip, "taskRu", $$v)},expression:"gip.taskRu"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3","disabled":_vm.isViewable},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Результат")]),_c('span',{staticClass:"lang-text"},[_vm._v("на русском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['gipData.resultRu'],
                    },attrs:{"id":"gip-res","disabled":_vm.isViewable || _vm.save},model:{value:(_vm.gip.resultRu),callback:function ($$v) {_vm.$set(_vm.gip, "resultRu", $$v)},expression:"gip.resultRu"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3","disabled":_vm.isViewable},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Целевой индикатор")]),_c('span',{staticClass:"lang-text"},[_vm._v("на русском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['gipData.targetIndicatorRu'],
                    },attrs:{"id":"gip-tarind","disabled":_vm.isViewable || _vm.save},model:{value:(_vm.gip.targetIndicatorRu),callback:function ($$v) {_vm.$set(_vm.gip, "targetIndicatorRu", $$v)},expression:"gip.targetIndicatorRu"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3","disabled":_vm.isViewable},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Прямой результат")]),_c('span',{staticClass:"lang-text"},[_vm._v("на русском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['gipData.directResRu'],
                    },attrs:{"id":"gip-dres","disabled":_vm.isViewable || _vm.save},model:{value:(_vm.gip.directResRu),callback:function ($$v) {_vm.$set(_vm.gip, "directResRu", $$v)},expression:"gip.directResRu"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3","disabled":_vm.isViewable},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Конечный результат")]),_c('span',{staticClass:"lang-text"},[_vm._v("на русском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['gipData.finalResRu'],
                    },attrs:{"id":"gip-fres","disabled":_vm.isViewable || _vm.save},model:{value:(_vm.gip.finalResRu),callback:function ($$v) {_vm.$set(_vm.gip, "finalResRu", $$v)},expression:"gip.finalResRu"}})],1),_c('b-form-group',{staticClass:"lang-form",attrs:{"label-cols":"3","disabled":_vm.isViewable},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"lang-label"},[_c('span',{staticClass:"main-text"},[_vm._v("Ключевой показатель")]),_c('span',{staticClass:"lang-text"},[_vm._v("на русском")])])]},proxy:true}])},[_c('b-form-textarea',{class:{
                        'invalid-field':
                            _vm.validationErrors['gipData.keyIndicatorRu'],
                    },attrs:{"id":"gip-keyind","disabled":_vm.isViewable || _vm.save},model:{value:(_vm.gip.keyIndicatorRu),callback:function ($$v) {_vm.$set(_vm.gip, "keyIndicatorRu", $$v)},expression:"gip.keyIndicatorRu"}})],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label-block"},[_c('span',{staticClass:"label-style bold pl-0"},[_vm._v("Планируемые значения (тыс. тенге)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label-block"},[_c('span',{staticClass:"label-style bold pl-0"},[_vm._v("Дополнительная инфомация")])])}]

export { render, staticRenderFns }